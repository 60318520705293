import React from "react"
import * as Icon from "react-feather"
const navigationConfig = [
  {
    id: "analytics",
    title: "Dashboard",
    type: "item",
    icon: <Icon.Home size={20} />,
    permissions: ["admin", "editor","Admin","ITAdmin","Accountant","Teacher"],
    navLink: "/analytics"
  },
  {
    id: "masters",
    title: "Masters",
    type: "collapse",
    icon: <Icon.FilePlus size={20} />,
    children: [
      {
        id: "class",
        title: "Class",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin","Admin","ITAdmin"],
        navLink: "/master/class"
      },
      {
        id: "section",
        title: "Section",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin","Admin","ITAdmin"],
        navLink: "/master/section"
      },
      {
        id: "semester",
        title: "Semester ",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin","Admin","ITAdmin"],
        navLink: "/master/semester"
      },
      {
        id: "feePlan",
        title: "Fee Plan ",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin","Admin","ITAdmin"],
        navLink: "/master/feePlan"
      },
      {
        id: "otherPayment",
        title: "Other Payment ",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin","Admin","ITAdmin"],
        navLink: "/master/otherPayment"
      },
      {
        id: "batchYear",
        title: "Batch Year ",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor","Admin","ITAdmin","Accountant","Teacher"],
        navLink: "/master/batchYear"
      },
    ]
  },
  {
    id: "admission",
    title: "Admission Details",
    type: "collapse",
    icon: <Icon.User size={20} />,
    children: [
      {
        id: "studentregistration",
        title: "Student Registration ",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor","Admin","ITAdmin","Accountant"],
        navLink: "/studentRegistration/edit"
      },
      {
        id: "studentlist2",
        title: "Student List ",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor","Admin","ITAdmin","Accountant","Teacher"],
        navLink: "/student/list"
      },
    
    ]
  },
  {
    id: "finance",
    title: "Finance",
    type: "collapse",
    icon: <Icon.Briefcase size={20} />,
    children: [
      {
        id: "feesubmit",
        title: "Fee Submission",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor","Admin","Accountant"],
        navLink: "/finance/fees"
      },
      {
        id: "studentfeediscount",
        title: "Student Freeship",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor","Admin","Accountant"],
        navLink: "/finance/studentfeediscount"
      },
      {
        id: "studentOtherPaymentDetail",
        title: "Other Payment",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor","Admin","Accountant"],
        navLink: "/finance/OtherPayment"
      },
      {
        id: "studentMis",
        title: "MIS",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor","Admin","Accountant"],
        navLink: "/finance/mis"
      },
      {
        id: "bouncePayment",
        title: "Bounce Payment",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor","Admin","Accountant"],
        navLink: "/finance/bouncePayment"
      },
      {
        id: "studentRefund",
        title: "Student Refund",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor","Admin","Accountant"],
        navLink: "/finance/studentRefund"
      },
    ]
  },
  {
    id: "report",
    title: "Reports",
    type: "collapse",
    icon: <Icon.BookOpen size={20} />,
    children: [
      {
        id: "feeReportReceipt",
        title: "Fee Report-Receipt",
        type: "item",
        //icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor","Admin","ITAdmin","Accountant"],
        navLink: "/report/feereportReceipt"
      },
      {
        id: "multipleFinanceReport",
        title: "Multiple Finance Report",
        type: "item",
        //icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor","Admin","ITAdmin","Accountant"],
        navLink: "/report/multiplefinancereport"
      },
      {
        id: "chequeBounceReport",
        title: "Cheque Bounce Report",
        type: "item",
        //icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor","Admin","Accountant"],
        navLink: "/report/chequebouncereport"
      },
      {
        id: "studentFeeAndDiscountDetailReport",
        title: "Student Fee And Freeship Detail Report",
        type: "item",
        //icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor","Admin","ITAdmin","Accountant"],
        navLink: "/report/studentfeeanddiscountdetailreport"
      },
    ]
  }
]

export default navigationConfig
