// import React from "react"
// // import { Navbar } from "reactstrap"
// // import classnames from "classnames"
// // import NavbarBookmarks from "./NavbarBookmarks"
// // import NavbarUser from "./NavbarUser"
// // import userImg from "../../../assets/img/portrait/small/avatar-s-11.jpg"

// // import React from 'react'
// // import PropTypes from 'prop-types'
// // import withStyles from '@material-ui/core/styles/withStyles'
// // import CssBaseline from '@material-ui/core/CssBaseline'
// import AppBar from '@material-ui/core/AppBar'
// import Toolbar from '@material-ui/core/Toolbar'
// // import Paper from '@material-ui/core/Paper'
// import Typography from '@material-ui/core/Typography'
// // import Divider from '@material-ui/core/Divider'
// // import StepForm from '../components/StepForm'
// import Link from '@material-ui/core/Link'
// import IconButton from '@material-ui/core/IconButton'
// // import SvgIcon from '@material-ui/core/SvgIcon'
// // import styled from '@emotion/styled'
// const ThemeNavbar = props => {
//   const colorsArr = [ "primary", "danger", "success", "info", "warning", "dark"]
//   const navbarTypes = ["floating" , "static" , "sticky" , "hidden"]
//   return (
//     <React.Fragment>
//       {/* className={classes.appBar} */}
//      			<AppBar  position="fixed" >
// 				<Toolbar>
// 					<Typography variant="h6" color="inherit" noWrap>
// 					Student Form
// 					</Typography>
// 					<IconButton color="inherit" aria-label="Github" style={{ marginLeft: 'auto' }}>
// 						<Link
// 							title="Github"
// 							component="a"
// 							rel="noopener noreferrer"
// 							target="_blank"
// 							href="http://github.com/awran5/react-material-ui-step-form"
// 							color="inherit"
// 						>
// 							<div>
// 								ss
// 							</div>
// 								{/* <HomeIcon /> */}
// 							{/* <SvgIcon style={{ fontSize: 30 }} viewBox="0 0 600 476.6"  width="200"   fontSize={"slarge"} component="svg">
// 								<path d="M12 .3a12 12 0 0 0-3.8 23.4c.6.1.8-.3.8-.6v-2c-3.3.7-4-1.6-4-1.6-.6-1.4-1.4-1.8-1.4-1.8-1-.7.1-.7.1-.7 1.2 0 1.9 1.2 1.9 1.2 1 1.8 2.8 1.3 3.5 1 0-.8.4-1.3.7-1.6-2.7-.3-5.5-1.3-5.5-6 0-1.2.5-2.3 1.3-3.1-.2-.4-.6-1.6 0-3.2 0 0 1-.3 3.4 1.2a11.5 11.5 0 0 1 6 0c2.3-1.5 3.3-1.2 3.3-1.2.6 1.6.2 2.8 0 3.2.9.8 1.3 1.9 1.3 3.2 0 4.6-2.8 5.6-5.5 5.9.5.4.9 1 .9 2.2v3.3c0 .3.1.7.8.6A12 12 0 0 0 12 .3" />
// 							</SvgIcon> */}
// 						</Link>
// 					</IconButton>
// 				</Toolbar>
// 			</AppBar>
//     </React.Fragment>
//   )
// }


// export default ThemeNavbar


import React from "react"
import { Navbar } from "reactstrap"
import classnames from "classnames"
import NavbarBookmarks from "./NavbarBookmarks"
import NavbarUser from "./NavbarUser"
import userImg from "../../../assets/img/portrait/small/avatar-s-11.jpg"
import { connect } from "react-redux"

const ThemeNavbar = props => {
  const colorsArr = [ "primary", "danger", "success", "info", "warning", "dark"]
  const navbarTypes = ["floating" , "static" , "sticky" , "hidden"]
  return (
    <React.Fragment>
      <div className="content-overlay" />
      <div className="header-navbar-shadow" />
      <Navbar
        className={classnames(
          "header-navbar navbar-expand-lg navbar navbar-with-menu navbar-shadow",
          {
            "navbar-light": props.navbarColor === "default" || !colorsArr.includes(props.navbarColor),
            "navbar-dark": colorsArr.includes(props.navbarColor),
            "bg-primary":
              props.navbarColor === "primary" && props.navbarType !== "static",
            "bg-danger":
              props.navbarColor === "danger" && props.navbarType !== "static",
            "bg-success":
              props.navbarColor === "success" && props.navbarType !== "static",
            "bg-info":
              props.navbarColor === "info" && props.navbarType !== "static",
            "bg-warning":
              props.navbarColor === "warning" && props.navbarType !== "static",
            "bg-dark":
              props.navbarColor === "dark" && props.navbarType !== "static",
            "d-none": props.navbarType === "hidden" && !props.horizontal,
            "floating-nav":
              (props.navbarType === "floating" && !props.horizontal) || (!navbarTypes.includes(props.navbarType) && !props.horizontal),
            "navbar-static-top":
              props.navbarType === "static" && !props.horizontal,
            "fixed-top": props.navbarType === "sticky" || props.horizontal,
            "scrolling": props.horizontal && props.scrolling

          }
        )}
      >
        <div className="navbar-wrapper">
          <div className="navbar-container content">
            <div
              className="navbar-collapse d-flex justify-content-between align-items-center"
              id="navbar-mobile"
            >
              <div className="bookmark-wrapper">
                <NavbarBookmarks
                  sidebarVisibility={props.sidebarVisibility}
                  handleAppOverlay={props.handleAppOverlay}
                />
              </div>
              {props.horizontal ? (
                <div className="logo d-flex align-items-center">
                  <div className="brand-logo mr-50"></div>
                  <h2 className="text-primary brand-text mb-0">Vuexy</h2>
                </div>
              ) : null}
              <NavbarUser
                handleAppOverlay={props.handleAppOverlay}
                changeCurrentLang={props.changeCurrentLang}
                userName={props.currentPersonName}
                userImg={ userImg }
              />
            </div>
          </div>
        </div>
      </Navbar>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  return {
    currentPersonName: state.auth.login.personName
  }
}

export default connect(mapStateToProps)(ThemeNavbar)
//export default ThemeNavbar
